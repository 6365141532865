import styled from "styled-components";

const Wrapper = styled.div`
  padding: 2em;
`;

const SmallText = styled.h5`
  font-size: 0.8em;
  color: #5a5e63;
`;

const Footer = () => {
  return (
    <Wrapper>
      <SmallText>
        This website provides information on casino bonuses for informational
        purposes only. We do not promote or endorse any form of gambling.
        Visitors are responsible for verifying the legality of online gambling
        in their jurisdiction and should always gamble responsibly. We strongly
        advise individuals to seek professional help if they believe they may
        have a gambling problem. We are not responsible for any inaccuracies,
        changes, or errors related to the information provided. Gamble
        responsibly and within your means.
      </SmallText>
    </Wrapper>
  );
};

export default Footer;
